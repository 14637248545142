import GLOBE from 'vanta/dist/vanta.globe.min';
import { useState, useRef, useEffect } from 'react';
import TechnologyList from './Technologies';

import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const customAnimation2 = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const customAnimation3 = keyframes`
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Home = () => {

    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)
    useEffect(() => {
      if (window.innerWidth > 600) {
        if (!vantaEffect) {
        setVantaEffect(GLOBE({
            el: myRef.current,
            color: "#1a5a93",
            backgroundColor: "#111827"
        }))
        }
        return () => {
        if (vantaEffect) vantaEffect.destroy()
        }
      }
    }, [vantaEffect]);

    return (
    <>
    <header className="header" id="header" ref={myRef}>
        <div className="header-text">
            <div className="header-text__content">
                <Reveal keyframes={customAnimation2} triggerOnce >
                    <p id="welcome">Üdvözöllek!</p>
                </Reveal>
                <Reveal keyframes={customAnimation3} triggerOnce>
                    <h1>Doszkocs Zoltán</h1>
                    <p id="devtext">vagyok, szoftverfejlesztő.</p>
                </Reveal>  
                <Reveal keyframes={customAnimation2} triggerOnce>
                    <a id="cv-button" href="/">CV letöltése</a>
                </Reveal>
            </div>
        </div>
        <div className="header-img">
            {/* ide lejet kepet tenni mobilon */}
        </div>
        <div class="mouse" id="mouse"></div>
    </header>
    <section className="about-me" id="about-me">
        <h2><b>{"<"}</b>Tapasztalat<b>{"/>"}</b></h2>
        <div className="experience">
            <Reveal keyframes={customAnimation} cascade damping={0.2} triggerOnce>
                <div className="exp-holder">
                    <div className="exp-icon1"></div>
                    <h3>Weboldalak és webapplikációk</h3>
                    <p>Tapasztalattal rendelkezem weboldalak és webapplikációk kidolgozásában. Továbbá, már meglévő weboldalak "felújítását" is végeztem.</p>
                    <a href="/">Tudj meg többet</a>
                </div>
                <div className="exp-holder">
                    <div className="exp-icon3"></div>
                    <h3>Mobil applikációk</h3>
                    <p>Több kommunikációt segítő, valamint vállalati ill. gyártási folyamatok felgyorsítását segítő mobil applikáció kidolgozásában vettem részt. </p>
                    <a href="/">Tudj meg többet</a>
                </div>
                <div className="exp-holder">
                    <div className="exp-icon2"></div>
                    <h3>Vállalat irányítási rendszerek</h3>
                    <p>5 éves tapasztalat ERP rendszer fejlesztésben, vállalat működését segítő szoftverek kidolgozásában, illetve ezek karbantartásában.</p>
                    <a href="/">Tudj meg többet</a>
                </div>
            </Reveal>
        </div>
    </section>
    <TechnologyList/>
    <section className='contact' id="contact">
      <h2><b>{"<"}</b>Kapcsolat<b>{"/>"}</b></h2>
      <Reveal keyframes={customAnimation} triggerOnce>
        <div className='contact-text'>
          <div className='contact-text__text'>
            <p>A postaládám mindig nyitva áll.</p>
            <p>Ha <b>kérdésed</b>, vagy egy <b>projekted</b> van írj bátran.</p>
            <p>Igyekszem minnél előbb válaszolni!</p>
            <a href="mailto:info@dzoltan.com">info@dzoltan.com</a>
          </div>
          <div className='contact-text__img'>
            <img src='img/mail.png' alt='mail'/>
          </div>
        </div>
      </Reveal>
    </section>
    </>
    );
  };
  
  export default Home;
