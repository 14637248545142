import React from "react";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

let Technologies = [
    {"name": "React", "img": "teh/react.png", "alt":"react" },
    {"name": "JavaScript", "img": "teh/js.png", "alt":"js" },
    {"name": "PHP", "img": "teh/php.png", "alt":"php" },
    {"name": "mySQL", "img": "teh/mysql.png", "alt":"mysql" },
    {"name": "Android", "img": "teh/android.png", "alt":"android" },
    {"name": "Sass", "img": "teh/sass.png", "alt":"sass" },
    {"name": "Bootstrap", "img": "teh/bootstrap.png", "alt":"bootstrap" },
    {"name": "GIT", "img": "teh/git.png", "alt":"git" },
    {"name": "Python", "img": "teh/python.png", "alt":"python" },
    {"name": "Photoshop", "img": "teh/photoshop.png", "alt":"Photoshop" }
];

const TechnologyList = () => {
    return (

        <section className="technologies" id="technologies">
          <h2><b>{"<"}</b>Technológiák és eszközök<b>{"/>"}</b></h2>
          <div className="tech-holder">
          <Reveal keyframes={customAnimation} cascade damping={0.1} triggerOnce>
            {Technologies.map((tech, index) => (
              <div key={index} className="technology">
                <div className="img-holder">
                    <img src={tech.img} alt={tech.alt} />
                </div>
                <p>{tech.name}</p>
              </div>
            ))}
          </Reveal>
          </div>
        </section>
    );
  };
  

export default TechnologyList;